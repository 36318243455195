/**Created by liaoyingchao on 11/23/22.*/

<template>
  <div class="main">
    <div class="header-div">
      <div class="logo-title">
        <div class="logo">
          <img src="../assets/logo.png">
        </div>
        <div class="web-title">运营管理平台</div>
      </div>
      <div class="right-div">
        <el-dropdown trigger="click" v-if="store.state.userInfo.userId">
          <span class="login-user">
            你好，{{ store.state.userInfo.userName }}
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="changePasswordEvent">修改密码</el-dropdown-item>
              <el-dropdown-item divided @click="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="main-body">
      <div class="menu-div">
        <el-menu
                active-text-color="#ffca11"
                background-color="#373E46"
                class="el-menu-vertical-demo"
                :default-active="defaultActive"
                text-color="#fff"
                :router="true"
        >
          <el-menu-item index="/home">
            <el-icon>
              <House/>
            </el-icon>
            <span>平台首页</span>
          </el-menu-item>
          <el-menu-item index="/member">
            <el-icon>
              <User/>
            </el-icon>
            <span>会员管理</span>
          </el-menu-item>
          <el-sub-menu index="Product">
            <template #title>
              <el-icon>
                <Goods/>
              </el-icon>
              <span>商品管理</span>
            </template>
            <el-menu-item index="/category">商品分类</el-menu-item>
            <el-menu-item index="/product">平台商品</el-menu-item>
            <el-menu-item index="/productstock">商品库存</el-menu-item>
            <!--<el-menu-item index="/specification">规格和价格</el-menu-item>-->
            <el-menu-item index="/salemanage">商品上架</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="Promotion">
            <template #title>
              <el-icon>
                <PriceTag/>
              </el-icon>
              <span>促销管理</span>
            </template>
            <el-menu-item index="/discount">商品折扣</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="Order">
            <template #title>
              <el-icon>
                <Tickets/>
              </el-icon>
              <span>订单管理</span>
            </template>
            <el-menu-item index="/order">平台订单</el-menu-item>
            <el-menu-item index="/refund">退款管理</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="Delivery">
            <template #title>
              <el-icon>
                <Van/>
              </el-icon>
              <span>配送管理</span>
            </template>
            <el-menu-item index="/deliveryman">配送员</el-menu-item>
            <el-menu-item index="/deliverylist">配送列表</el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="Report">
            <template #title>
              <el-icon>
                <DataAnalysis/>
              </el-icon>
              <span>统计与对账</span>
            </template>
            <el-menu-item index="/dailyreport">销售日报</el-menu-item>
            <el-menu-item index="/monthlyreport">销售月报</el-menu-item>
          </el-sub-menu>
          <!--<el-menu-item index="7">-->
          <!--<template #title>-->
          <!--<el-icon><Setting /></el-icon>-->
          <!--<span>设置</span>-->
          <!--</template>-->
          <!--</el-menu-item>-->
        </el-menu>
      </div>
      <div class="main-content">
        <router-view/>
      </div>
    </div>
    <el-dialog
            class="center-dialog"
            v-model="dialogVisible"
            title="修改密码"
            width="500px"
            destroy-on-close
    >
      <div class="dialog-content" v-loading="pageLoading">
        <el-form ref="Ref_editForm" :model="editForm" :rules="formRules" label-width="120px" style="width: 95%;">
          <el-form-item label="短信验证码" prop="code">
            <el-input v-model="editForm.code" placeholder="请输入短信验证码">
              <template #append>
                <el-button @click="getPasswordCode" :disabled="codeTime > 0">{{ codeTime ? (codeTime +"秒重新获取") : "获取验证码" }}</el-button>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword">
            <el-input v-model="editForm.newPassword" placeholder="请输入新密码" show-password/>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="saveEvent">保存</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
  import {
    House,
    User,
    Goods,
    PriceTag,
    Van,
    Tickets,
    DataAnalysis,
    Setting,
  } from '@element-plus/icons-vue'
  import {ref, reactive, watch} from 'vue'
  import {useRoute, useRouter} from 'vue-router'
  import userApi from "../api/user";
  import {ElMessage, ElMessageBox} from 'element-plus';
  import store from '@/store'
  import token from "../utils/token";
  import FormCheck from "../utils/formCheck";
  import md5String from "../utils/md5";

  const route = useRoute()
  const router = useRouter()

  const defaultActive = ref('/home')

  defaultActive.value = route.path

  watch(
    () => router.currentRoute.value,
    (newValue) => {
      defaultActive.value = newValue.path
    },
    { immediate: true }
  )

  function getUserInfo() {
    userApi.getUserInfo().then(res => {
      if (res.code == 0) {
        store.state.userInfo = res.data
      } else {
        ElMessage.error(res.msg);
      }
    })
  }

  getUserInfo();

  function loginOut() {
    ElMessageBox.confirm('您确定需要退出登录吗？', '退出提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      userApi.loginOut().then(res => {
        if (res.code == 0) {
          store.state.userInfo = {}
          token.removeToken();

          // ElMessage.success("退出登录成功！");
          router.push({
            path: '/login'
          })
        } else {
          ElMessage.error(res.msg);
        }
      })
    }).catch((e) => {
      console.error(e)
    })
  }

  const Ref_editForm = ref(null)
  let dialogVisible = ref(false)
  const editForm = reactive({
    code: '',
    newPassword: ''
  })
  const formRules = {
    code: FormCheck.required('请输入验证码'),
    newPassword: FormCheck.required('请输入新密码'),
  }
  const pageLoading = ref(false)
  const codeTime = ref(0)

  function changePasswordEvent() {
    dialogVisible.value = true;
    editForm.code = ''
    editForm.newPassword = ''
  }

  function saveEvent() {
    Ref_editForm.value.validate((valid, fields) => {
      if (valid) {
        console.log('submit!')
        changePassword();
      } else {
        console.log('error submit!', fields)
      }
    })
  }
  
  function getPasswordCode() {
    pageLoading.value = true
    userApi.getPasswordCode({}).then(res => {
      if (res.code == '0') {
        codeTime.value = 60;
        let interval = setInterval(() => {
          codeTime.value --
          if (codeTime.value <= 0) {
            clearInterval(interval);
          }
        }, 1000)
        ElMessage.success("获取验证码成功！请及时查看！");
      } else {
        ElMessage.error(res.msg || '请求接口失败！');
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }

  function changePassword() {
    pageLoading.value = true
    userApi.changePassword({
      code: editForm.code,
      userPassword: md5String(editForm.newPassword),
    }).then(res => {
      if (res.code == '0') {
        dialogVisible.value = false;
        ElMessage.success("密码更改成功！");
      } else {
        ElMessage.error(res.msg || '请求接口失败！');
      }
    }).finally(() => {
      pageLoading.value = false
    })
  }
</script>

<style lang="stylus" scoped type="text/stylus">
  .main {
    width 100%;
    height: 100%;
    overflow hidden;

    .header-div {
      height 60px;
      display flex;
      align-items center;
      justify-content space-between;
      border-bottom 1px solid #ddd;

      .logo-title {
        display flex;
        align-items center;

        .logo {
          width 167px;
          padding 8px 10px 6px;

          img {
            display block;
            height 46px;
          }
        }

        .web-title {
          padding-top 14px;
          font-size 22px;
        }
      }

      .right-div {
        padding-right 20px;
        .login-user {
          cursor pointer;
        }
      }
    }

    .main-body {
      height calc(100% - 60px);
      background-color #efefef;
      display flex;

      .menu-div {
        width 201px;
        height 100%;

        >>> .el-menu {
          height 100%;
          overflow-x hidden;
          overflow-y auto;
        }
      }

      .main-content {
        flex 1;
        height 100%;
        padding 10px;
        overflow hidden;
      }
    }
  }
</style>