import http from '@/utils/http'

const user = {
  login(data) {
    return http.post('/api/user/login', data)
  },
  getLoginCode(data) {
    return http.post('/api/user/getlogincode', data)
  },
  getUserInfo(data) {
    return http.post('/api/user/getuserinfo', data)
  },
  loginOut(data) {
    return http.post('/api/user/loginout', data)
  },
  getPasswordCode(data) {
    return http.post('/api/user/getpasswordcode', data)
  },
  changePassword(data) {
    return http.post('/api/user/changepassword', data)
  }
}

export default user;